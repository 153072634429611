@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&family=Neonderthaw&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,200;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shrikhand&display=swap);
:root {
  --background:#060606;
  --text-primary: white;
  --text-secondary: rgb(132, 142, 255);
  --accent: rgb(228, 99, 0);
  --main-text:"Shrikhand", cursive;
  --body-text:"Poppins", sans-serif;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size:16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:white;
  background-color: #000000!important;
}

h2 {
  font-family: "Shrikhand", cursive;
  font-family: var(--main-text);
  font-weight: 600;
  font-size: 40px;
  color:rgb(228, 99, 0)!important;
}

#glow {
  font-size: 60px;
  color: rgb(255, 255, 255);
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
  text-shadow: 0 0 10px rgba(251, 251, 251, 0.612), 0 0 20px rgba(249, 232, 190, 0.654), 0 0 30px #e63d006a, 0 0 40px #3e06224f, 0 0 50px #fc91c624, 0 0 60px #c4b37e9e;
}

/*-------Navbar--------*/
.navbar-nav {
  font-size: 1.25rem;
  align-items: center;
}

.brand {
  font-size: 2rem;
  font-weight: 500;
  margin-left: 2rem;
  padding-top: 2rem;
}

.nav-links {
  color: rgb(255, 255, 255);
  text-decoration:none;
  padding: 0 1rem;
  display:table;
}

.navbar-logo {
  color: rgb(255, 255, 255);
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

#navPic {
  height:18%;
  width: 10%;
}

#nav-link:hover {
  color: rgb(253, 141, 49);
  font-size:larger;
  background-color: transparent !important;
  border: none !important;
}

a:hover {
  color:rgb(228, 99, 0);
  color:var(--accent);
}

.dropdown-menu.show{
  background-color: rgba(228, 99, 0, 0.4) !important;
  border: none !important;
}

#collapsible-nav-dropdown{
  background-color: transparent !important;
  border: none !important;
}

/* ------Carousel base class-------- */

.logo-carousel {
  position: absolute;
  z-index: 10;
  height: 215px;
  /* width: 300px; */
  margin: 0 auto;
  opacity: 0.9;
  top:215px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

.carousel-indicators {
  opacity: 0.1;
  background-color: black;
  border-radius: 100%;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}

.carousel-item > img {
  /* position: absolute; */
  -webkit-mask-image: linear-gradient(to top, transparent 55%, rgb(0, 0, 0));
          mask-image: linear-gradient(to top, transparent 55%, rgb(0, 0, 0));
  overflow: hidden;
  top: 0;
  left: 0;
  min-width: 100%;
  /* height: 100%; */
  border-radius: 5px; 
}

.carousel {
  transition: transform .4s ease-in, opacity .4s ease-out;
  position: relative!important;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
}

#carousel-text {
  font-family: "Poppins", sans-serif!important;
  font-family: var(--body-text)!important;
}

/* Welcome section HOME */
#grad1 {
  height: 200px;
  background-color: black; /* For browsers that do not support gradients */
  background-image: linear-gradient(black, grey);
  color: white;
}

.welcome {
  text-align: center;
  /* padding-bottom: 1rem !important; */
}

.welcome h1 {
  font-weight: 700;
}

/* ======== Cards HOME======= */
.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.img-card{
  width:inherit;
  /* max-height: auto; */
}
#home-img, #vinyl-img {
  max-height: 15rem;
  object-fit: cover;
}

.mixboard {
  max-height: 15rem; /* Safari 6.0 - 9.0 */
  filter:sepia(50%);
  object-fit: fill;
}

.home-text {
  text-transform: capitalize;
}

#work-homeBtn {
  justify-content: center;
  background-color: rgb(228, 99, 0);
  background-color: var(--accent);
  color:white;
  border-radius: 5px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: 500;
  color:rgb(255, 255, 255);
  text-shadow: 2px 2px 4px #000000;
}

.overlay-home {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color:#060606;
  background-color:var(--background);
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: .3s ease;
}
.bg-home {
  background-color: #060606!important;
  background-color: var(--background)!important;
}

#home-card:hover .overlay-home {
  transform: scale(1);
}

.home-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

h-75 {
  height: 75%!important;
}


/* ======= ABOUT ========= */
.about-container {
  padding-top: 2rem;
}

#about {
  padding-bottom: 2rem;
  margin-left: 3rem;
}

#about-music {
  margin: 0 3rem;
}

#about-jeff {
  margin-top: 3rem;
  margin-bottom: 1rem;
  margin-left: 3rem;
}

#jeff-text {
  margin-top: 1.5rem;
}

#studio, #about-jeff, #control {
  border-radius: 5px;
}

.about-sub {
  color:yellow!important;
  opacity: 0.65;
  font-size: 55px!important;
}

#interview-link:hover {
  background-color: rgb(253, 141, 49)!important;
}

/* Featurettes
------------------------- */


.featurette-divider {
  margin: 2rem 0;
}

hr {
  color:rgb(248, 250, 250) !important;
}

/* Thin out the marketing headings */
.featurette-heading {
  font-weight: 300;
  line-height: 1;
  /* rtl:remove */
  letter-spacing: -.05rem;
}

.featurette-heading2 {
  font-family: 'Poppins', sans-serif;
}


#studioHeader {
  color: white;
}

.lead {
  color: white;
}

/*----- Gear List--------*/
.list-group-item{
  background-color: black!important;
  color:white!important;
}

/*------Audio page-----*/
#micImgAudio{
  /* position: relative; */
  overflow: hidden;
  max-width: 100%;
  -webkit-mask-image: linear-gradient(to top, transparent 45%, rgb(23, 22, 21));
          mask-image: linear-gradient(to top, transparent 45%, rgb(23, 22, 21));
}

.Audio-header {
  max-height: 475px;
  /* overflow: hidden; */
}

#sample-title {
  text-align: center;
  font-size: 3rem;
}

/* #audiorock{
  background-image: url('./assets/country.jpg');
  background-repeat: no-repeat;
  background-size: cover;
} */
/* .overlay {
  position:relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
} */

#ImgHeading{
  position: absolute;
  text-align: center;
  top: 40%;
  /*left: 50%; */
  font-size: 2rem;
  color: white;
  /* transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%); */
  line-height: normal;
  padding: 0 2.5rem;
  word-wrap: break-word;
}

#ImgText{
  position:absolute;
  text-align: center;
  top: 40%;
  left: 50%;
  font-size: 1.5rem;
  color: rgb(255, 255, 255);
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

#cardGroup{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  /* margin: 0 4rem; */
}

div.card-title.h5 {
  color: white;
  font-size: 1rem;
}

#card-logo{
  width: 45%;
}

#audioCard{
  /* max-height: 20rem;*/
  width: 100%;
  margin: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}

#audio-img {
  margin: 9px 20px;
  width:50%;
}

.audio-text {
  margin-top: 10px;
}
#audio-card-background-corp{
  height: 100%;
  width: 250px;
}
#audio-card-background-country{
  height: 100%;
  width: 250px;
  
}
#audio-card-background-voice{
  height: 100%;
  width: 250px;
}

#cardLinks {
  text-decoration: none;
}

#cardGroup2 {
  display: flex;
  justify-content:space-evenly;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

div.card-title.h5 {
  color: white;
  font-size: 1.5rem;
}

/*==========STUDIO=========*/
.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.27), rgba(0, 0, 0, 0.4)), url(/static/media/MillsMixBrdVin.a169ed09.png);
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.serv-header {
  column-count: 3;
  grid-column-gap: 30px;
  column-gap: 30px;
  column-rule: 1px solid orange;
}

#studio-svc {
  column-span: all;
}

#studio-collage {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

#collage {
  flex: 25% 1;
  max-width: 25%;
  padding: 0 4px;
}

#collage img{
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/*  GEAR LIST-EQUIPMENT */

.gear-title{
  margin-top:.5rem;
}

.list-group-item {
  padding:0 0!important;
}

#equip-group {
  margin-left:9rem;
  margin-bottom: 5px;
}

.gear-group {
  margin-top:1rem;
  margin-bottom: 0;
  color:rgb(228, 99, 0);
  color:var(--accent)
}

#orange {
  color:lightsalmon;
}

/*======== BOOKING ==========*/
#booking-info {
  margin: 0 auto;
  max-width: 75%;
}

#booking p {
  font-size: 18px;
}

.btn-book {
  background-color: coral;
}

#micBG {
  max-width: 400px;
  margin-top: 6rem;
}
.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  /* transform: rotate(30deg); */
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}

/* VIDEO */
/* #video-content {
  background-image: url('./assets/cameralens.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  opacity: 0.75;
  width: 100%;
}

#video-img {
  max-width: 50%!important;
} */

/* ========CONTACT ===========*/
.contact {
  background-image: url(/static/media/360v5.8a3c7153.png);
  background-repeat: no-repeat;
  background-size: auto;
  background-position:center;
  z-index: -1;
}

#contact-header {
  font-family: "Shrikhand", cursive;
  font-family: var(--main-text);
  color:rgb(228, 99, 0)!important;
  font-size:45px;
}

.card-header {
  font-size: 30px;
  /* font-weight: 700; */
}

.contact-card {
  max-width: 24rem;
  background-color: black !important;
  z-index: 9;
}
.contact-card:hover {
  opacity: 1.0;
}

#contact-send {
  margin-top: 1rem;
  background-color: rgb(255, 106, 52);
  border:none;
}

#contact-list {
  background-color: black;
  color:white;
}
/* .contact-form {
  /* position: absolute; */
  /* background: #3c3c3c;
  color: #FFF;
  font-family: sans-serif;
  padding: 20px;
  top: 50%;
  left: 50%;
  border-radius: 1rem;
  transform: translate(-50%, -50%);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12);
} */

/* ========= FOOTER ======== */
#footer {
  margin-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

.footer-right p, .footer-middle p {
  font-size: 16px;
  color:rgb(255, 255, 255);
}

ul.footer-about {
  list-style: none;
  padding-left: 0px;
}

ul.footer-about li {
  color:white;
  font-weight: 300;
  line-height: 1.5;
  font-size: 14px;
}

ul.footer-about li a {
  color: rgba(196, 42, 42, 0.5);
  font-weight: 500;
  text-transform: none;
}
ul.footer-about a:hover {
  color: rgb(220, 166, 72);
  text-decoration: none;
  list-style: none;
}
.footer-middle h5, .footer-right h5, .footer-left h5 {
  font-size: 20px;
  color:rgba(255, 255, 255, 0.5);
}
.footer-middle {
  text-align: center;
  padding: 0px;
  
}
.featured-in {
  width: 100%;
  margin: 5px auto;
  text-align: center;
  color:white;
}
.featured-in p {
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}
img.feature-logo {
  max-width: 30%;
  align-items: center;
}
.footer-left {
  text-align: left;
  justify-content: space-evenly;
}
.footer-right {
  margin-left: auto;
  display: flex;
  justify-content:space-evenly;
}
.footer-main {
  display: flex;
  margin-right: 1px;
  margin: auto;
}
svg {
  width: 2rem;
  color:rgb(18, 208, 251);
}

.footer-bottom {
  display:flex;
  flex-direction: column;
  align-items: center;
}
.footer-copyright {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  padding-top: 10px;
  align-items: center;
}

/* ======== Separate AUDIO Cards/PLAYER =======*/
#audiorock {
  max-width: 100%;
  margin: 0 auto;
}

#genre-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

#card-player {
  padding-top: 1rem;
  width: 25rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 29px;
  background-color: #000000;
  border: 1.2px solid rgba(255, 47, 43, 0.154);
  box-shadow: 5px 10px 20px -9px rgba(255, 255, 255, 0.31);
}

/* ======== Waveform ======= */

#waveform {
  /* background-image: url('./assets/360v5.png'); */
  /* margin-top: 7rem; */
  margin-right: auto;
  margin-left: auto;
  max-width: 25rem;
  background: rgb(0, 0, 0);
  border-radius: 2.25rem;
  /* box-shadow: 5px 10px 20px -5px rgba(195, 195, 195, 0.386),
      5px 6px 6px -10px rgba(255, 254, 254, 0.516); */
  padding-top: 2rem;
}

#wave {
  margin: 0 9px;
}

button {
  background: rgba(0, 0, 0, 0);
  color: white;
  border: none;
  width: -webkit-max-content;
  width: max-content;
  height: 70px;
  /* border-radius: 100%; */
  outline: none;
  text-transform: uppercase;
  transition: all 0.2s ease;
}

#playerBtn {
  margin-right: 5px;
  margin-left: 7rem;
  background-image: url(/static/media/360PlayPause.684ed6f9.png);
  background-size: 65px;
  background-repeat: no-repeat;
  color:transparent;
  display:block;
  width: 100%;
}

#playerBtn:hover {
  width: 150%;
}

#playerBtn:active {
  /* box-shadow: 0 5px #666; */
  transform: translateY(6px);
}

#waveform-label {
  color: #f6a761;
  padding-left: 1px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
}

/* #btn-player {
  background: url('./assets/360Play.png');
  background-size: contain;
  background-repeat: no-repeat;
  color:transparent;
  padding: 0 1rem;
  margin-left: 3rem
} */

/* button:hover {
  background: #ffc32a;
} */

#playlist {
  text-align:center;
  font-family: 'Fjalla One', sans-serif;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 2rem;
}

.playlist-item {
  color: rgb(255, 69, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.playlist-item.selected {
  font-weight: bold;
  background-color: #9696968a;
  border-radius: 5px;
}

.playlist-item:hover {
  background: rgba(0, 0, 0, 0.1);
}

#playlist-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  text-align: center;
  color: white;
}

.controls {
  text-align: left;
  padding: 1rem;
}

#player-img {
  max-width: 20rem;
  margin-bottom: 2rem;
  margin-right:2rem;
  margin-left: 1.8rem;
  background-color: rgba(255, 226, 196, 0.79);
  border-radius: 1rem;
  box-shadow: 9px 7px 15px -3px rgb(74, 54, 36),
      -5px 7px 10px 1px rgba(245, 131, 8, 0.243);
}

#volume {
  -webkit-appearance: none;
  width: 60%;
  margin-left:1rem;
  margin-right: 1%;
  height: 5px;
  border-radius: 5px;
  background: #2f2d2c;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

#speaker {
  width: 10%;
  margin: 2px;
}

#trackTitle ul{
  list-style-type: none;
  font-family: 'Fjalla One', sans-serif;
}

button#trackTitle {
  height: 1.5rem;
}

/* ====== MEDIA =========== */

@media (max-width:991px) {
  .navbar-nav {
    color: rgb(255, 255, 255);
    background-color: rgb(48, 32, 14);
  }
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .footer-main {
    padding: 5px 0px 25px 0px;
  }
  #ImgHeading, #ImgText {
    font-size: 18px;
    position: relative;
  }

  #collage {
    flex: 50% 1;
    max-width: 50%;
  }

  .serv-header {
    column-count: 1;
    grid-column-gap: 30px;
    column-gap: 30px;
    column-width: auto;
  }
}

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (max-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
  #cardGroup {
    display: flex;
    flex-wrap:nowrap;
    max-width:100%;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .column {
    width: 100%;
  }
  .container {
    max-width: auto;
  }
  #ImgHeading, #ImgText {
    font-size: 18px;
  }

  div.card-title.h5 {
    font-size: 34px;
  }

  .btn {
    font-size: 24px;
  }

  .brand.navbar-brand {
    color:rgba(251, 251, 251, 0.478);
    font-size: small;
  }

  #cardGroup {
    display: flex;
    flex-wrap: wrap;
    max-width:100%;
    align-items: center;
  }

  #audioCard {
    margin: 5px 0;
    
  }

  #card-player {
    margin: 0 0;
    width:100%;
  }

  #sample-title {
    font-size:28px;
  }

  #footer .footer-main {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      text-align: center;
  }
  .footer-left {
    text-align: -webkit-center;
  }

  #about {
    margin-left: 2px;
  }

  .featurette-heading, .featurette-heading2 {
    font-family: 'Poppins', sans-serif;
  }

  #about-music {
    margin: 0 .5rem;
  }

  #about-jeff {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 0;
  }

  #collage {
    flex: 100% 1;
    max-width: 100%;
  }

  #equip-group{
    margin-left:1rem;
  }

}

@media (max-width: 375px) {
  #ImgHeading, #ImgText {
    font-size: 18px;
    top: 15%;
  }

  #sample-title {
    font-size:20px;
    font-family: 'Poppins', sans-serif;
  }

  #card-player {
    width: auto;
  }
  
  #player-img {
    width: revert;
  }
  #playerBtn {
    margin-left: 6rem;
    width:auto;
  }

  #trackTitle {
    font-size: small;
  }  

}

@media (min-width:990px) and (max-width: 1197px) {
  #vinyl-img {
    object-fit: cover;
    max-height: 12rem;
  }

}

@media (min-width:820px) and (max-width:1199px){
  .serv-header {
    column-count: auto;
    grid-column-gap: 40px;
    column-gap: 40px;
    column-rule: 1px solid orange;
    text-transform: uppercase;
    width:85%;
    margin: 0 auto;
    font-weight: 500;
  }

  #studio-svc {
    text-transform: none!important;
  }
}
/* @media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
} */


